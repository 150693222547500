import {
    getTitleFromGenericRoute,
    initialOffersCalls,
    hasSpecialCharacters,
} from "@lib/sharedUtils";
import {
    Category,
    ClickOffer,
    TranslationContent,
    ServerRequest,
    ListingItem,
} from "@lib/shared/types";

import { resolveOperatingSystem } from "@lib/antiviruschoice/utils";
import { GetServerSidePropsContext, GetServerSidePropsResult } from "next";
import { ParsedUrlQuery } from "querystring";
import {
    getAffiliateListId,
    getListId,
    getTimeOnSite,
    setLanderCookie,
} from "@lib/shared/cookies";
import get from "lodash/get";
import {
    DomainForm,
    DomainInfo,
    DomainPopup,
    getComparisonListing,
    getDKIs,
    getDomainForm,
    getDomainInfo,
    getDomainPopup,
    getListingBySlug,
    getUserSession,
    VisitDetails,
    Listing,
    ComparisonListing,
    getClicksSearchList,
    getClickLink,
    getGenericRoutes,
    getLocationByZipCode,
    ZipCodeLocation,
    getOfferUrl,
    getAliasUrl,
    getListingBySlugV3,
    getBlog,
    getRouteConfig,
    DKI,
    GenericRoutes,
    getLookupsAndVariations,
    getPiiCompletion,
} from "src/api";
import { ListingV3 } from "src/api/listings";
import { getTranslation } from "@lib/sharedUtils";
import { gettingQueriesAsString } from "@lib/shared/routerQueriesToString";
import { questions } from "@components/shared/listingFilters/questions";
import { AffiliateOfferZone } from "@lib/shared/config";
import { Session, SessionTestConfig } from "src/api/session";
import { BlogPostType } from "src/api/getBlog";
import { Dispatch, MutableRefObject, SetStateAction } from "react";
import {
    ReplacedPortion,
    RouteConfig,
    PageBuilderBlogPost,
    PageBuilderLookupLists,
    PageBuilderListings,
} from "@page-builder/ts/types";
import { AxiosError } from "axios";
import { PiiCompletion } from "src/api/piiCompletion";

const allowedSlugsForMakersApi = [
    "auto-warranty",
    "car-warranty",
    "autowarranty",
];
// domains with type informational that need to get form
const informationalDomainsToGetForm = [
    "severalbrands.com",
    "premiumcoverage.com",
];
interface Route {
    id: number;
    route: string;
    isActive: boolean;
    isDeleted: boolean;
    ignoreKeyword: boolean;
    category: Category;
    title: string | null;
}
export interface DomainRoute {
    id: number;
    domainId: number;
    route: string;
    isActive: boolean;
    ignoreKeyword: boolean;
    isDeleted: boolean;
}

export interface Options {
    hasOperatingSystem?: boolean | undefined;
    extraCalls?: ["getLocation"];
    extraProps?: { [x: string]: string | boolean | number | object | [] };
    pageTitle?: string;
    pageDescription?: string;
    getZipCodeLocation?: { zipCode: string };
    getExternalOffersOnThankYouPage?: boolean;
    activateOfferFormTemplate?: boolean;
    getSpecialDomainListing?: boolean;
    lander?: string;
    isStaticContent?: boolean;
    slug?: string;
    fetchForm?: boolean;
    zipCodeFallback?: boolean; // we use this flag in listing page to skip the fallback
}
export type AutoWarrantyMakers = { label: string; value: string; id: number }[];

export type PageProps = {
    domain: DomainInfo;
    visitDetails: VisitDetails;
    testConfig?: SessionTestConfig;
    category?: Category | null;
    form?: DomainForm | null;
    formattedTitle?: string | null;
    clicksOffers?: ClickOffer[] | null;
    domainPopup?: DomainPopup | null;
    comparisonListing?: ComparisonListing | null;
    categoryListing?: Listing | null;
    pageTitle: null | string;
    zipCodeLocation?: ZipCodeLocation | null;
    zipCode: string | undefined;
    operatingSystem?: string | null;
    locale: string;
    locales: string[] | null;
    defaultLocale: string | null;
    content: TranslationContent | null;
    error?: { statusCode: 500 };
    userAgent?: string;
    isBot: boolean;
    formTemplate?: string | null | string[];
    formOffer?: ListingItem[] | [];
    exitModalOffer?: ListingItem[] | [];
    thankYouOffers?: ListingItem[] | [];
    headerOffer?: ListingItem[] | [];
    stickyOffer?: ListingItem[] | [];
    thankyouListing?: ListingV3 | null;
    secondListing?: ListingV3 | null;
    blogPost: BlogPostType | null;
    totalTimeSpendOnSite: MutableRefObject<number>;
    initialTimeOnSite: string;
    autoWarrantyMakers: AutoWarrantyMakers;

    routeConfig?: RouteConfig;
    externalFavicon?: string | null;
    pageBuilderBlogPost?: PageBuilderBlogPost;
    pageBuilderLookupLists?: PageBuilderLookupLists;
    pageBuilderListings?: PageBuilderListings;

    piiCompletionData?: PiiCompletion;
    redirectTo?: string;
    disableTimeOnSite?: boolean;
    showFormModal?: boolean;
    setShowFormModalState?: Dispatch<SetStateAction<boolean | undefined>>;
    pageType: string;
    doNotSellFormStates?: { label: string; id: number; value: string }[] | null;
};

const isCategoryExist = (
    categorySlugAlias: string,
    domain: DomainInfo,
): boolean => {
    if (
        domain.categories.find((domainCategory: Category) => {
            return domainCategory.slugAlias === categorySlugAlias;
        })
    ) {
        return true;
    } else if (
        domain.categories.find(
            (domainCategory: Category) =>
                domainCategory.slug === categorySlugAlias,
        )
    ) {
        return true;
    }
    return false;
};

const isListingExist = (listingSlug: string, domain: DomainInfo): boolean => {
    if (
        !domain.listings.find(
            (domainCategory) => domainCategory.slug === listingSlug,
        )
    ) {
        return false;
    }
    return true;
};

const gettingCategory = (
    categorySlugAlias: string,
    domainCategories: Category[],
): Category | undefined => {
    return domainCategories.find((category) => {
        return (
            category.slugAlias === categorySlugAlias ||
            category.slug === categorySlugAlias
        );
    });
};
const getMakers = async (
    req: ServerRequest,
    categorySlugAlias: string,
    domain: DomainInfo,
) => {
    const currentCategory =
        gettingCategory(categorySlugAlias, domain.categories) ??
        domain.categories[0];
    if (allowedSlugsForMakersApi.includes(currentCategory.slug)) {
        const data = await getLookupsAndVariations({
            req,
            source: "lookups",
            queries: {
                orderBy: "label",
                orderByDirection: "asc",
                type: "make",
                variationsIds: undefined,
                lookupsId: undefined,
            },
        });
        return data ?? [];
    }
    return { data: [] };
};
const handleOptions = async ({
    options,
    props,
    req,
}: {
    options: Options;
    props: Partial<PageProps>;
    req: ServerRequest;
}): Promise<Partial<PageProps>> => {
    const tempProps: Partial<PageProps> & {
        [key: string]: any;
    } = { ...props };

    for (const option of Object.keys(options)) {
        switch (option) {
            case "getZipCodeLocation": {
                if (options.getZipCodeLocation?.zipCode) {
                    const { data: location } = await getLocationByZipCode({
                        zipCode: options.getZipCodeLocation.zipCode,
                        req,
                    });

                    tempProps["zipCodeLocation"] = location;
                }

                break;
            }

            case "hasOperatingSystem": {
                const os = resolveOperatingSystem(req.headers["user-agent"]);

                tempProps["operatingSystem"] = os;

                break;
            }

            case "pageTitle": {
                if (props.formattedTitle) {
                    tempProps["pageTitle"] = props.formattedTitle?.replace(
                        /<br>/g,
                        "",
                    );
                } else {
                    tempProps["pageTitle"] = decodePageTitle(
                        options.pageTitle as string,
                        props,
                    );
                }

                break;
            }

            case "extraProps": {
                if (options.extraProps) {
                    for (const extraPropKey of Object.keys(
                        options.extraProps,
                    )) {
                        tempProps[extraPropKey] =
                            options.extraProps[extraPropKey];
                    }
                }
                break;
            }
        }
    }

    return tempProps;
};

const decodePageTitle = (text: string, props: Partial<PageProps>) => {
    let clonedText = text;

    const dynamicStrings = text?.match(/\${([^}]*)}/g);

    dynamicStrings?.forEach((item) => {
        if (
            get(props, item.replace("${", "").replace("}", "").replace("?", ""))
        ) {
            const temp = clonedText.replace(
                item,
                get(
                    props,
                    item.replace("${", "").replace("}", "").replace("?", ""),
                ) as string,
            );

            clonedText = temp;
        } else {
            clonedText = text.replace(item, "");
        }
    });

    return clonedText;
};

const orderingList = (
    keyword: string | undefined,
    list: ListingItem[],
): ListingItem[] => {
    if (keyword) {
        const listItemMatched = list?.find((item) => {
            if (item.slug.indexOf(keyword.toLocaleLowerCase()) > -1) {
                return item;
            }
        });
        if (listItemMatched) {
            listItemMatched.hiddenAttributes.scoreValue = "9.9";
            listItemMatched.hiddenAttributes.scoreMsg = "Exceptional";

            return [
                listItemMatched,
                ...list.filter((item) => item.slug !== listItemMatched.slug),
            ];
        }

        return list;
    }
    return list;
};
export const getPageProps = async (
    context: GetServerSidePropsContext<ParsedUrlQuery>,
    pageType:
        | "home"
        | "listing"
        | "genericRoute"
        | "staticCategory"
        | "category"
        | "comparison"
        | "genericRouteAsCategory"
        | "thankyou"
        | "offer"
        | "claims"
        | "redirect"
        | "alias"
        | "fbLander"
        | "blogPost"
        | "unsubscribe"
        | "do-not-sell"
        | "page-builder"
        | "form-completion"
        | "common-offers"
        | "common-offers-generic"
        | "common-offers-generic-as-category"
        | "autoWarranty"
        | "partner-with-us",
    options?: Options,
): Promise<GetServerSidePropsResult<{ [key: string]: any }>> => {
    let allRoutes: (Route | DomainRoute)[] = [];
    const pageTitle = null;
    let routeTitle: string | null | undefined = null;
    let genericRouteTitle:
        | {
              formattedTitle: string | null;
              notFound: boolean;
              goToHome?: boolean;
          }
        | undefined;

    let categoryListingOffer: ListingItem[] | null = null;
    const { res, query } = context;
    const req = context.req as ServerRequest;
    const initialTimeOnSite = getTimeOnSite(req, res) ?? 0;

    const {
        genericRoute,
        keyword,
        ts,
        zc, // zipCode
        listingSlug,
        replaceKeyword,
        offerSlug,
        id: listReqId,
        filters,
        k: offerKeyword,
        alias,
    } = query;
    const categorySlugAlias =
        pageType === "genericRouteAsCategory"
            ? query.category
            : options?.slug ?? query.category;

    if (
        categorySlugAlias &&
        hasSpecialCharacters(categorySlugAlias as string)
    ) {
        return {
            notFound: true,
        };
    }
    if (pageType === "alias") {
        if (!alias) {
            return {
                notFound: true,
            };
        }
        const { data: url, error } = await getAliasUrl({
            req,
            alias: alias as string,
        });

        if (!url || error) {
            return {
                notFound: true,
            };
        } else {
            return {
                redirect: {
                    destination: url,
                    permanent: true,
                },
            };
        }
    }

    interface InitialCalls {
        getUserSession: ReturnType<typeof getUserSession>;
        getGenericRoutes?: ReturnType<typeof getGenericRoutes>;
        getDKIs?: ReturnType<typeof getDKIs>;
    }

    const initialCalls: InitialCalls = {
        getUserSession: getUserSession({
            req: req,
            res,
            query,
            options,
        }),
    };

    if (
        pageType === "genericRoute" ||
        pageType === "genericRouteAsCategory" ||
        pageType === "common-offers-generic" ||
        pageType === "common-offers-generic-as-category"
    ) {
        initialCalls.getGenericRoutes = getGenericRoutes({ req });
        initialCalls.getDKIs = getDKIs({ req });
    }

    type InitialPromiseArray = [
        Awaited<ReturnType<typeof getUserSession>>?,
        Awaited<ReturnType<typeof getGenericRoutes>>?,
        Awaited<ReturnType<typeof getDKIs>>?,
    ];

    let promiseArray: InitialPromiseArray = [];

    let genericRoutes: GenericRoutes | null = null;
    let session: Session | null = null;
    let sessionApiError: AxiosError | null | undefined;
    let genericRoutesError: AxiosError | null | undefined;
    let DKIs: DKI[] | null = [];

    try {
        promiseArray = (await Promise.all(
            Object.values(initialCalls),
        )) as InitialPromiseArray;
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log("e=>", e);
    }

    if (promiseArray && promiseArray.length) {
        session = promiseArray[0]?.data as Awaited<
            ReturnType<typeof getUserSession>
        >["data"];

        sessionApiError = promiseArray[0]?.error;
        genericRoutesError = promiseArray[1]?.error;

        if (Object.keys(initialCalls).length > 1) {
            DKIs = promiseArray[2]?.data ?? null;
            genericRoutes = promiseArray[1]?.data ?? null;
        }
    }

    // 1) get session
    // if the session api has error, page response will be 500
    if (sessionApiError || !session || !session.visitDetails) {
        if (sessionApiError?.response?.status === 403) {
            req.isBlocked = true;
            return {
                notFound: true,
            };
        }
        if (sessionApiError?.response?.status === 422) {
            res.statusCode = 400;
            return {
                props: {
                    error: { statusCode: 400 },
                },
            };
        }
        res.statusCode = 500;
        return {
            props: {
                error: { statusCode: 500 },
            },
        };
    }

    let props: Partial<PageProps> = {
        visitDetails: session.visitDetails,
        testConfig: session.testConfig,
        pageTitle,
        zipCode: (zc as string) ?? null,
        locale: req.locale ?? "en",
        locales: req.locales,
        defaultLocale: req.defaultLocale,
        userAgent: req.headers["user-agent"],
        isBot:
            session.visitDetails.visitId === "bot-visit" ||
            session.visitDetails.visitorId === "bot-visitor",
        initialTimeOnSite: initialTimeOnSite.toString(),
        pageType: pageType,
    };

    let routeConfig: RouteConfig | null = null;
    if (pageType === "page-builder") {
        const finalizeSlug = (slug: string | string[] | undefined) => {
            if (Array.isArray(slug)) {
                return slug.join("/");
            }
            return slug ?? "/";
        };

        const finalSlug = finalizeSlug(context.query.slug);

        const { data, error } = await getRouteConfig({
            req,
            slug: finalSlug,
        });

        routeConfig = data ?? null;

        if (error) {
            if (error.response?.status === 404) {
                return {
                    notFound: true,
                };
            } else {
                return {
                    props: {
                        error: { statusCode: 500 },
                    },
                };
            }
        }
        //@ts-ignore
        const parsedUrl = new URL(
            //@ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            `${req.protocol as string}://${req.get("host") as string}${
                //@ts-ignore
                req.originalUrl as string
            }`,
        );
        // disables the page builder title for home page
        props["pageTitle"] =
            parsedUrl?.pathname === "/" ? "" : routeConfig?.config?.seo?.title;

        if (routeConfig?.replacedPortions) {
            const genericRoute = routeConfig?.replacedPortions.find(
                (item) => item?.key === "[generic-route]",
            ) as ReplacedPortion<"[generic-route]"> | undefined;

            const keyword = routeConfig?.replacedPortions.find(
                (item) => item?.key === "[keyword]",
            ) as ReplacedPortion<"[keyword]"> | undefined;

            const category = routeConfig?.replacedPortions.find(
                (item) => item?.key === "[category]",
            ) as ReplacedPortion<"[category]"> | undefined;

            if (category) {
                props["category"] = category.item;
            }

            if (genericRoute && keyword) {
                routeTitle = genericRoute.item?.title;

                const DKIsResponse = await getDKIs({ req });
                DKIs = DKIsResponse.data;

                genericRouteTitle = getTitleFromGenericRoute(
                    keyword.item || "",
                    DKIs,
                    genericRoute.item ? [genericRoute.item] : [],
                    genericRoute.value,
                    ts,
                    req?.headers?.referer,
                    replaceKeyword,
                    session?.visitDetails?.trafficSourceNetwork as string,
                    session?.visitDetails?.region,
                    session?.visitDetails?.city,
                    props["category"]?.name ||
                        genericRoute.item?.category?.name,
                );

                if (genericRouteTitle.notFound) {
                    req.isBlocked = true;
                    return {
                        notFound: true,
                    };
                }
                if (genericRouteTitle.goToHome) {
                    return {
                        redirect: {
                            destination: "/",
                            permanent: false,
                        },
                    };
                }
                props["pageTitle"] =
                    genericRouteTitle.formattedTitle?.replace(/<br>/g, "") ??
                    routeTitle;
                props["formattedTitle"] = genericRouteTitle.formattedTitle;
            }

            const post = routeConfig?.replacedPortions.find(
                (item) => item?.key === "[post]",
            ) as ReplacedPortion<"[post]"> | undefined;

            if (post) {
                props["pageBuilderBlogPost"] = post.item;
                props["pageTitle"] = post.item?.title ?? null;
            }
        }
    }

    // 2) get domain info
    const { data: domain, error: domainError } = await getDomainInfo({
        req,
        categorySlug:
            pageType === "page-builder"
                ? props.category?.slug
                : pageType === "genericRouteAsCategory" ||
                  pageType === "common-offers-generic-as-category"
                ? null
                : (categorySlugAlias as string),
    });

    props["domain"] = domain ?? undefined;
    if (!props["pageTitle"]) {
        props["pageTitle"] = domain?.title ?? null;
    }

    // if domain has tenant but does not exist from the backend
    if (!domain || domainError) {
        if (domainError && domainError?.response?.status === 404) {
            return {
                notFound: true,
            };
        }
        return {
            props: {
                error: { statusCode: 500 },
            },
        };
    }

    if (pageType === "redirect") {
        const link: string = domain?.defaultOffers[0].slug ?? "";
        if (link) {
            return {
                redirect: {
                    permanent: false,
                    destination: `/offer/${link}?zone=external&categoryId=${domain?.defaultOffers[0].category.id}`,
                },
                props: {},
            };
        }
        return {
            props: {},
        };
    }

    let content;
    if (req.locale) {
        content = getTranslation({
            domain: domain.name,
            locale: req.locale,
        });
        props["content"] = content ?? null;
    }

    // getRoutes and check if route not exist return 404
    if (
        pageType === "genericRoute" ||
        pageType === "genericRouteAsCategory" ||
        pageType === "common-offers-generic" ||
        pageType === "common-offers-generic-as-category"
    ) {
        if (genericRoutesError) {
            return {
                redirect: {
                    destination: "/",
                    permanent: false,
                },
            };
        }

        const finalGenericRoute =
            pageType === "genericRouteAsCategory" ||
            pageType === "common-offers-generic-as-category"
                ? categorySlugAlias
                : genericRoute;

        const finalKeyword =
            pageType === "genericRouteAsCategory" ||
            pageType === "common-offers-generic-as-category"
                ? genericRoute
                : keyword;

        const category =
            gettingCategory(categorySlugAlias as string, domain.categories) ??
            domain.categories[0];

        routeTitle = genericRoutes?.categoryRoutes?.find(
            (item) =>
                item?.route === genericRoute &&
                "category" in item &&
                category.slug === item.category.slug,
        )?.title;

        if (genericRoutes) {
            allRoutes = [
                ...genericRoutes.domainRoutes,
                ...genericRoutes.categoryRoutes,
            ];
        }

        if (
            !allRoutes.map((x) => x.route).includes(finalGenericRoute as string)
        ) {
            return {
                notFound: true,
            };
        }

        genericRouteTitle = getTitleFromGenericRoute(
            finalKeyword as string,
            DKIs,
            allRoutes,
            finalGenericRoute,
            ts,
            req?.headers?.referer,
            replaceKeyword,
            session?.visitDetails?.trafficSourceNetwork as string,
            session?.visitDetails?.region,
            session?.visitDetails?.city,
            category?.name,
        );

        if (genericRouteTitle.notFound) {
            req.isBlocked = true;

            return {
                notFound: true,
            };
        }
        if (genericRouteTitle.goToHome) {
            return {
                redirect: {
                    destination: "/",
                    permanent: false,
                },
            };
        }
    }

    // if page type is category or url has category, check if the category exist
    if (
        (pageType === "category" ||
            (pageType === "genericRoute" && categorySlugAlias)) &&
        !isCategoryExist(categorySlugAlias as string, domain)
    ) {
        return {
            notFound: true,
        };
    } else if (pageType !== "page-builder") {
        const currentCategory =
            gettingCategory(
                options?.slug ?? (categorySlugAlias as string),
                domain.categories,
            ) ?? domain.categories[0];

        props["category"] = currentCategory ?? null;
    }

    // get domain popup if the domain type is affiliate
    if (domain.type === "affiliate") {
        const { data: domainPopup } = await getDomainPopup({ req });
        props["domainPopup"] = domainPopup ?? null;
    }

    const getClaimsTemplates = () => {
        const { formTemplate } = query;
        const allowedShapes = ["v1", "v2"];
        if (!allowedShapes.includes(formTemplate as string)) {
            return true;
        }

        props["formTemplate"] = formTemplate;
    };

    switch (pageType) {
        case "home":
            if (domain.categories.length === 1) {
                props["category"] = domain.categories[0] ?? null;
                if (domain.type === "lead" || options?.fetchForm) {
                    if (!options?.isStaticContent) {
                        const { data: form } = await getDomainForm({
                            req,
                            res,
                            categorySlug: props.category.slug,
                        });
                        const [
                            formOffer,
                            exitModalOffer,
                            thankYouOffers,
                            headerOffer,
                            stickyOffer,
                        ] = initialOffersCalls(
                            domain,
                            req,
                            res,
                            categoryListingOffer,
                            session.visitDetails,
                            props.category.slug,
                        );
                        props["formOffer"] = formOffer ?? [];
                        props["exitModalOffer"] = exitModalOffer ?? [];
                        props["thankYouOffers"] = thankYouOffers ?? [];
                        props["headerOffer"] = headerOffer ?? [];
                        props["stickyOffer"] = stickyOffer ?? [];
                        props["form"] = form ?? null;
                    }
                }
            }

            // eslint-disable-next-line no-case-declarations
            const { data: makersData } = await getMakers(
                req,
                categorySlugAlias as string,
                domain,
            );

            props["autoWarrantyMakers"] =
                (makersData as AutoWarrantyMakers) ?? [];
            props["pageTitle"] = domain.title;
            break;
        case "thankyou": {
            if (!query?.data) {
                return {
                    redirect: {
                        destination: "/",
                        permanent: false,
                    },
                };
            }

            const slug: string = props?.category?.slug ?? "";
            const currentCategory = gettingCategory(slug, domain.categories);

            const [
                formOffer,
                exitModalOffer,
                thankYouOffers,
                headerOffer,
                stickyOffer,
            ] = initialOffersCalls(
                domain,
                req,
                res,
                categoryListingOffer,
                session.visitDetails,
                currentCategory?.slug,
            );
            props["formOffer"] = formOffer ?? [];
            props["exitModalOffer"] = exitModalOffer ?? [];
            props["thankYouOffers"] = thankYouOffers ?? [];
            props["headerOffer"] = headerOffer ?? [];
            props["stickyOffer"] = stickyOffer ?? [];

            if (options?.getSpecialDomainListing) {
                const slug = domain.listings[0]?.slug;
                if (slug) {
                    const { data: listing } = await getListingBySlugV3({
                        req,
                        res,
                        slug,
                    });
                    props["thankyouListing"] = listing;
                    props["thankYouOffers"] = [];
                }
            }

            if (domain.categories.length === 1) {
                props["category"] = domain.categories[0] ?? null;
                if (domain.type === "lead") {
                    const { data: form } = await getDomainForm({
                        req,
                        res,
                        categorySlug: props.category.slug,
                    });

                    if (form?.listing?.slug) {
                        const { data: categoryListing } =
                            await getListingBySlugV3({
                                req,
                                res,
                                slug: form.listing.slug,
                            });
                        props["thankyouListing"] = categoryListing;
                    }
                    if (domain.config?.listSlug) {
                        const { data: secondListing } =
                            await getListingBySlugV3({
                                req,
                                res,
                                slug: domain.config?.listSlug,
                            });
                        props["secondListing"] = secondListing;
                    }

                    props["form"] = form ?? null;
                }
            } else if (currentCategory) {
                props["category"] = currentCategory ?? null;
                if (domain.type === "lead") {
                    const { data: form } = await getDomainForm({
                        req,
                        res,
                        categorySlug: props.category.slug,
                    });
                    if (form?.listing?.slug) {
                        const { data: categoryListing } =
                            await getListingBySlugV3({
                                req,
                                res,
                                slug: form.listing.slug,
                            });
                        props["thankyouListing"] = categoryListing;
                    }

                    props["form"] = form ?? null;
                }
            }

            props["pageTitle"] = domain.title;

            const crossSellingDomainFormId = currentCategory
                ? currentCategory.crossSellingDomainFormId
                : domain.categories[0].crossSellingDomainFormId;

            if (
                options?.getExternalOffersOnThankYouPage &&
                query?.zc &&
                crossSellingDomainFormId
            ) {
                type Data = { [x: string]: string };

                const fieldsObject: Data = JSON.parse(
                    Buffer.from(query.data as string, "base64").toString(
                        "utf-8",
                    ),
                ) as Data;

                const finalQuestionsObject: Record<
                    string,
                    { name: string; value: string }
                > = {};

                Object.keys(fieldsObject).forEach((item) => {
                    finalQuestionsObject[item] = {
                        name: item,
                        value: fieldsObject[item],
                    };
                });

                let { data: clicksSearchList } = await getClicksSearchList({
                    questions: {
                        ...finalQuestionsObject,
                    },
                    categorySlug: currentCategory?.slug ?? "auto-insurance",
                    crossSellingDomainFormId,
                    req,
                    res,
                });

                if (
                    clicksSearchList?.result.length === 0 ||
                    !clicksSearchList
                ) {
                    const response = await getClicksSearchList({
                        questions: {
                            zipCode: {
                                value: "10019",
                                name: "zipCode",
                            },
                        },
                        categorySlug: currentCategory?.slug ?? "auto-insurance",
                        crossSellingDomainFormId,
                        req,
                        res,
                    });

                    clicksSearchList = response.data;
                }

                if (clicksSearchList && clicksSearchList.result.length) {
                    clicksSearchList.result.forEach((item, index) => {
                        clicksSearchList!.result[index].slug = `${
                            clicksSearchList?.result[index].slug as string
                        }?id=${clicksSearchList!.listRequestId}`;
                    });
                }

                props["clicksOffers"] = clicksSearchList?.result ?? null;
            }

            break;
        }
        case "genericRoute":
        case "genericRouteAsCategory":
            props["formattedTitle"] = genericRouteTitle?.formattedTitle ?? null;
            if (domain.categories?.length === 1) {
                props["category"] = domain.categories[0] ?? null;
                switch (domain.type) {
                    case "lead": {
                        const { data: form } = await getDomainForm({
                            req,
                            res,
                            categorySlug: props.category.slug,
                        });
                        props["form"] = form ?? null;

                        const [
                            formOffer,
                            exitModalOffer,
                            thankYouOffers,
                            headerOffer,
                            stickyOffer,
                        ] = initialOffersCalls(
                            domain,
                            req,
                            res,
                            categoryListingOffer,
                            session.visitDetails,
                            props.category.slug,
                        );
                        props["formOffer"] = formOffer ?? [];
                        props["exitModalOffer"] = exitModalOffer ?? [];
                        props["thankYouOffers"] = thankYouOffers ?? [];
                        props["headerOffer"] = headerOffer ?? [];
                        props["stickyOffer"] = stickyOffer ?? [];

                        if (options?.activateOfferFormTemplate) {
                            const goToNotFound = getClaimsTemplates();
                            if (goToNotFound) {
                                return {
                                    notFound: true,
                                };
                            }
                        }
                        break;
                    }
                    case "informational": {
                        if (
                            informationalDomainsToGetForm.includes(domain?.name)
                        ) {
                            const { data: form } = await getDomainForm({
                                req,
                                res,
                                categorySlug: props.category.slug,
                            });
                            props["form"] = form ?? null;
                        }

                        break;
                    }
                    case "affiliate": {
                        const { data: comparisonListing } =
                            await getComparisonListing({ req, res });
                        props["comparisonListing"] = comparisonListing ?? null;

                        if (comparisonListing?.listingsInfo?.pageTitle) {
                            props["pageTitle"] =
                                comparisonListing.listingsInfo.pageTitle;
                        }
                        break;
                    }
                    case "clicks":
                        if (options?.fetchForm) {
                            const { data: form } = await getDomainForm({
                                req,
                                res,
                                categorySlug: props.category.slug,
                            });
                            props["form"] = form ?? null;

                            const [
                                formOffer,
                                exitModalOffer,
                                thankYouOffers,
                                headerOffer,
                                stickyOffer,
                            ] = initialOffersCalls(
                                domain,
                                req,
                                res,
                                categoryListingOffer,
                                session.visitDetails,
                                props.category.slug,
                            );
                            props["formOffer"] = formOffer ?? [];
                            props["exitModalOffer"] = exitModalOffer ?? [];
                            props["thankYouOffers"] = thankYouOffers ?? [];
                            props["headerOffer"] = headerOffer ?? [];
                            props["stickyOffer"] = stickyOffer ?? [];
                        }
                        if (ts && pageType === "genericRouteAsCategory") {
                            return {
                                redirect: {
                                    destination: `/${
                                        props.category.slugAlias
                                    }/offers?${gettingQueriesAsString(
                                        query,
                                        "start",
                                        ["genericRoute", "category"],
                                    )}`,
                                    permanent: false,
                                },
                            };
                        }
                }
            } else if (domain.categories?.length > 1 && categorySlugAlias) {
                switch (domain.type) {
                    case "lead": {
                        if (domain.type === "lead" && props.category) {
                            const { data: form } = await getDomainForm({
                                req,
                                res,
                                categorySlug: props.category?.slug,
                            });
                            props["form"] = form;
                            const [
                                formOffer,
                                exitModalOffer,
                                thankYouOffers,
                                headerOffer,
                                stickyOffer,
                            ] = initialOffersCalls(
                                domain,
                                req,
                                res,
                                categoryListingOffer,
                                session.visitDetails,
                                props.category.slug,
                            );

                            props["formOffer"] = formOffer ?? [];
                            props["exitModalOffer"] = exitModalOffer ?? [];
                            props["thankYouOffers"] = thankYouOffers ?? [];
                            props["headerOffer"] = headerOffer ?? [];
                            props["stickyOffer"] = stickyOffer ?? [];

                            if (options?.activateOfferFormTemplate) {
                                if (
                                    props.category.slug !== "auto-accident" &&
                                    domain.name === "lawyerconsultation.com"
                                ) {
                                    return {
                                        notFound: true,
                                    };
                                }
                                const goToNotFound = getClaimsTemplates();
                                if (goToNotFound) {
                                    return {
                                        notFound: true,
                                    };
                                }
                            }
                        }
                        break;
                    }
                    case "informational": {
                        if (
                            domain?.name === "severalbrands.com" &&
                            props.category
                        ) {
                            const { data: form } = await getDomainForm({
                                req,
                                res,
                                categorySlug: props.category?.slug,
                            });
                            props["form"] = form;
                            const [
                                formOffer,
                                exitModalOffer,
                                thankYouOffers,
                                headerOffer,
                                stickyOffer,
                            ] = initialOffersCalls(
                                domain,
                                req,
                                res,
                                categoryListingOffer,
                                session.visitDetails,
                                props.category.slug,
                            );

                            props["formOffer"] = formOffer ?? [];
                            props["exitModalOffer"] = exitModalOffer ?? [];
                            props["thankYouOffers"] = thankYouOffers ?? [];
                            props["headerOffer"] = headerOffer ?? [];
                            props["stickyOffer"] = stickyOffer ?? [];
                        }
                        break;
                    }
                    case "clicks":
                        if (ts && pageType === "genericRoute") {
                            return {
                                redirect: {
                                    destination: `/${
                                        categorySlugAlias as string
                                    }/offers?${gettingQueriesAsString(
                                        query,
                                        "start",
                                        ["genericRoute", "keyword", "category"],
                                    )}`,
                                    permanent: false,
                                },
                            };
                        }
                }
            }

            // eslint-disable-next-line no-case-declarations
            const { data: makersDataGeneric } = await getMakers(
                req,
                categorySlugAlias as string,
                domain,
            );

            props["autoWarrantyMakers"] =
                (makersDataGeneric as AutoWarrantyMakers) ?? [];
            break;

        case "category":
            switch (domain.type) {
                case "clicks": {
                    const { data: clicksSearchList } =
                        await getClicksSearchList({
                            questions: {
                                zipCode: {
                                    value: "10019",
                                    name: "zipCode",
                                },
                            },
                            categorySlug:
                                gettingCategory(
                                    categorySlugAlias as string,
                                    domain.categories,
                                )?.slug || "",
                            req,
                            res,
                        });

                    if (clicksSearchList && clicksSearchList.result?.length) {
                        clicksSearchList.result.forEach((item, index) => {
                            clicksSearchList.result[
                                index
                            ].slug = `${clicksSearchList.result[index].slug}?id=${clicksSearchList.listRequestId}`;
                        });
                    }

                    props["clicksOffers"] = clicksSearchList?.result ?? null;
                }
            }

            if (domain.categories.length > 1) {
                if (
                    (domain?.type === "lead" ||
                        domain?.name === "severalbrands.com") &&
                    props.category
                ) {
                    const { data: form } = await getDomainForm({
                        req,
                        res,
                        categorySlug: props.category.slug,
                    });
                    const [
                        formOffer,
                        exitModalOffer,
                        thankYouOffers,
                        headerOffer,
                        stickyOffer,
                    ] = initialOffersCalls(
                        domain,
                        req,
                        res,
                        categoryListingOffer,
                        session.visitDetails,
                        props.category.slug,
                    );
                    props["formOffer"] = formOffer ?? [];
                    props["exitModalOffer"] = exitModalOffer ?? [];
                    props["thankYouOffers"] = thankYouOffers ?? [];
                    props["headerOffer"] = headerOffer ?? [];
                    props["stickyOffer"] = stickyOffer ?? [];
                    props["form"] = form ?? null;
                }
            }
            props["pageTitle"] =
                (props.category?.title || props.category?.name) ?? null;

            break;

        case "listing":
            switch (domain.type) {
                case "clicks": {
                    if (!isCategoryExist(categorySlugAlias as string, domain)) {
                        return {
                            notFound: true,
                        };
                    }

                    if ((!zc && !ts) || !categorySlugAlias) {
                        return {
                            redirect: {
                                destination: "/",
                                permanent: false,
                            },
                        };
                    }

                    const gettingValuesFromQuery = () => {
                        const tempObject: {
                            [x: string]: { value: string; name: string };
                        } = {};

                        if (filters) {
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                            const parsedFilters: { [x: string]: string } =
                                filters &&
                                JSON.parse(
                                    Buffer.from(
                                        filters as string,
                                        "base64",
                                    ).toString("utf-8"),
                                );
                            Object.keys(parsedFilters).forEach((filterKey) => {
                                tempObject[filterKey] = {
                                    value: parsedFilters[filterKey],
                                    name: filterKey,
                                };
                            });
                        } else {
                            questions[
                                (categorySlugAlias as string) ?? ""
                            ].forEach((question) => {
                                if (question.value) {
                                    tempObject[question.name] = {
                                        value: question.value,
                                        name: question.name,
                                    };
                                }
                            });
                        }

                        return tempObject;
                    };

                    let { data: clicksSearchList } = await getClicksSearchList({
                        questions: {
                            ...gettingValuesFromQuery(),
                            ...(zc && {
                                zipCode: {
                                    value: zc as string,
                                    name: "zipCode",
                                },
                            }),
                        },
                        categorySlug:
                            gettingCategory(
                                categorySlugAlias as string,
                                domain.categories,
                            )?.slug || "",
                        zipCodeFallback: options?.zipCodeFallback,
                        req,
                        res,
                    });

                    if (
                        clicksSearchList?.result.length === 0 ||
                        !clicksSearchList
                    ) {
                        const response = await getClicksSearchList({
                            questions: {
                                ...gettingValuesFromQuery(),
                                zipCode: {
                                    value: "10019",
                                    name: "zipCode",
                                },
                            },
                            categorySlug:
                                gettingCategory(
                                    categorySlugAlias as string,
                                    domain.categories,
                                )?.slug || "",
                            req,
                            res,
                        });

                        clicksSearchList = response.data;
                    }

                    if (clicksSearchList && clicksSearchList.result.length) {
                        clicksSearchList.result.forEach((item, index) => {
                            clicksSearchList!.result[index].slug = `${
                                clicksSearchList?.result[index].slug as string
                            }?id=${clicksSearchList!.listRequestId}`;
                        });
                    }

                    props["clicksOffers"] = clicksSearchList?.result ?? null;

                    break;
                }

                case "affiliate":
                case "lead": {
                    if (!isListingExist(listingSlug as string, domain)) {
                        return {
                            notFound: true,
                        };
                    }

                    if (domain.type === "lead") {
                        props["category"] = domain.categories[0] ?? null;

                        const { data: form } = await getDomainForm({
                            req,
                            res,
                            categorySlug: props.category.slug,
                        });
                        props["form"] = form ?? null;
                    }

                    const { data: categoryListing } = await getListingBySlug({
                        req,
                        res,
                        slug: listingSlug as string,
                    });

                    if (
                        Array.isArray(categoryListing?.offers) &&
                        categoryListing?.offers.length
                    ) {
                        categoryListing.offers = orderingList(
                            offerKeyword as string | undefined,
                            categoryListing.offers,
                        );
                    }

                    props["categoryListing"] = categoryListing ?? null;
                    categoryListingOffer = categoryListing?.offers ?? [];
                    if (categoryListing?.pageTitle) {
                        props["pageTitle"] = categoryListing?.pageTitle;
                    }

                    break;
                }
            }
            break;

        case "offer": {
            const listReqId_ = (getListId(req, res) as string) ?? listReqId;
            const affiliateListReqId_ = getAffiliateListId(req, res) as string;

            switch (domain.type) {
                case "clicks": {
                    if (!listReqId_ || !offerSlug) {
                        return {
                            redirect: {
                                destination: "/",
                                permanent: false,
                            },
                        };
                    }
                    const { data: url } = await getClickLink({
                        req,
                        slug: offerSlug as string,
                        listRequestId: listReqId_,
                    });

                    if (url) {
                        return {
                            redirect: {
                                destination: url,
                                permanent: false,
                            },
                        };
                    }

                    return {
                        redirect: {
                            destination: "/",
                            permanent: false,
                        },
                    };
                    break;
                }

                case "affiliate":
                case "lead": {
                    if (!offerSlug) {
                        return {
                            redirect: {
                                destination: "/",
                                permanent: false,
                            },
                        };
                    }
                    // @ts-ignore
                    if (query && query.categoryId && isNaN(query.categoryId)) {
                        return {
                            notFound: true,
                        };
                    }
                    const { data: url } = query.isExternalOffer
                        ? await getClickLink({
                              req,
                              slug: offerSlug as string,
                              listRequestId: listReqId_,
                          })
                        : await getOfferUrl({
                              req,
                              slug: offerSlug as string,
                              listId: affiliateListReqId_,
                              categoryId: query.categoryId as
                                  | number
                                  | undefined,
                              affLinkId: query.affLinkId as number | undefined,
                              zone: query.zone as AffiliateOfferZone,
                          });

                    if (url) {
                        return {
                            redirect: {
                                destination: url,
                                permanent: false,
                            },
                        };
                    }

                    return {
                        redirect: {
                            destination: "/",
                            permanent: false,
                        },
                    };
                }
            }
            break;
        }

        case "comparison":
            if (domain.type === "affiliate") {
                const { data: comparisonListing } = await getComparisonListing({
                    req,
                    res,
                });
                if (
                    Array.isArray(comparisonListing?.offers) &&
                    comparisonListing?.offers.length
                ) {
                    comparisonListing.offers = orderingList(
                        offerKeyword as string | undefined,
                        comparisonListing.offers,
                    );
                }
                props["comparisonListing"] = comparisonListing ?? null;
                if (comparisonListing?.listingsInfo?.pageTitle) {
                    props["pageTitle"] =
                        comparisonListing.listingsInfo.pageTitle;
                }
            }
            break;
        case "claims": {
            const [
                formOffer,
                exitModalOffer,
                thankYouOffers,
                headerOffer,
                stickyOffer,
            ] = initialOffersCalls(
                domain,
                req,
                res,
                categoryListingOffer,
                session.visitDetails,
                domain.categories[0].slug,
            );

            const goToNotFound = getClaimsTemplates();
            if (goToNotFound) {
                return {
                    notFound: true,
                };
            }
            if (domain.categories.length === 1) {
                props["category"] = domain.categories[0] ?? null;
                if (domain.type === "lead") {
                    const { data: form } = await getDomainForm({
                        req,
                        res,
                        categorySlug: props.category.slug,
                    });

                    props["form"] = form ?? null;
                    props["formOffer"] = formOffer ?? [];
                    props["exitModalOffer"] = exitModalOffer ?? [];
                    props["thankYouOffers"] = thankYouOffers ?? [];
                    props["headerOffer"] = headerOffer ?? [];
                    props["stickyOffer"] = stickyOffer ?? [];
                }
            }
            if (domain.categories.length > 1) {
                if (domain.type === "lead" && props.category) {
                    if (
                        props.category.slug !== "auto-accident" &&
                        domain.name === "lawyerconsultation.com"
                    ) {
                        return {
                            notFound: true,
                        };
                    }
                    const { data: form } = await getDomainForm({
                        req,
                        res,
                        categorySlug: props.category.slug,
                    });
                    const [
                        formOffer,
                        exitModalOffer,
                        thankYouOffers,
                        headerOffer,
                        stickyOffer,
                    ] = initialOffersCalls(
                        domain,
                        req,
                        res,
                        categoryListingOffer,
                        session.visitDetails,
                        props.category.slug,
                    );
                    props["formOffer"] = formOffer ?? [];
                    props["exitModalOffer"] = exitModalOffer ?? [];
                    props["thankYouOffers"] = thankYouOffers ?? [];
                    props["headerOffer"] = headerOffer ?? [];
                    props["stickyOffer"] = stickyOffer ?? [];
                    props["form"] = form ?? null;
                }
            }

            break;
        }
        case "blogPost": {
            const { data: blog } = await getBlog({
                req,

                slug: context.query.slug as string[],
            });

            if (!blog) {
                return {
                    notFound: true,
                };
            }
            props["blogPost"] = blog ?? null;
            if (domain.categories.length === 1) {
                props["category"] = domain.categories[0] ?? null;
                if (domain.type === "lead") {
                    const { data: form } = await getDomainForm({
                        req,
                        res,
                        categorySlug: props.category.slug,
                    });
                    const [
                        formOffer,
                        exitModalOffer,
                        thankYouOffers,
                        headerOffer,
                        stickyOffer,
                    ] = initialOffersCalls(
                        domain,
                        req,
                        res,
                        categoryListingOffer,
                        session.visitDetails,
                        props.category.slug,
                    );
                    props["formOffer"] = formOffer ?? [];
                    props["exitModalOffer"] = exitModalOffer ?? [];
                    props["thankYouOffers"] = thankYouOffers ?? [];
                    props["headerOffer"] = headerOffer ?? [];
                    props["stickyOffer"] = stickyOffer ?? [];
                    props["form"] = form ?? null;
                }
            } else {
                if (domain.type === "lead") {
                    const { data: form } = await getDomainForm({
                        req,
                        res,
                        categorySlug:
                            blog?.model?.category?.slug ?? "auto-accident",
                    });
                    const [
                        formOffer,
                        exitModalOffer,
                        thankYouOffers,
                        headerOffer,
                        stickyOffer,
                    ] = initialOffersCalls(
                        domain,
                        req,
                        res,
                        categoryListingOffer,
                        session.visitDetails,
                        blog?.model?.category?.slug ?? "auto-accident",
                    );
                    props["formOffer"] = formOffer ?? [];
                    props["exitModalOffer"] = exitModalOffer ?? [];
                    props["thankYouOffers"] = thankYouOffers ?? [];
                    props["headerOffer"] = headerOffer ?? [];
                    props["stickyOffer"] = stickyOffer ?? [];
                    props["form"] = form ?? null;
                }
            }
            props["pageTitle"] = domain.title;
            break;
        }
        case "fbLander":
            setLanderCookie(
                props["domain"]?.niceName.split(".")[0] as string,
                req,
                res,
            );
            if (domain.categories.length === 1) {
                props["category"] = domain.categories[0] ?? null;
            }
            props["pageTitle"] = domain.title;
            break;
        case "page-builder": {
            let formToBeFetchedCategory: string | undefined;
            const lookupsToBeFetched: string[] = [];
            const listingsToBeFetched: string[] = [];
            let fetchExternalListing: boolean = false;

            if (query.slug?.includes("thank-you")) {
                if (!query?.data && query?.previewPb !== "true") {
                    return {
                        redirect: {
                            destination: "/",
                            permanent: false,
                        },
                    };
                }
            }

            props["externalFavicon"] = domain.favicon?.fullPath ?? null;

            if (!props.category) {
                props["category"] = domain.categories[0];
            }

            routeConfig?.config?.sections?.forEach((section) => {
                section.components?.forEach((component) => {
                    if (component.id === "Form") {
                        if (
                            !component.props.formCategory ||
                            component.props.formCategory?.auto
                        ) {
                            formToBeFetchedCategory = props.category?.slug;
                        } else if (
                            component.props.formCategory?.categories?.length ===
                            1
                        ) {
                            formToBeFetchedCategory =
                                component.props.formCategory?.categories?.[0]
                                    ?.slug;
                        }
                    }
                    if (component.id === "ThankYou") {
                        formToBeFetchedCategory = props.category?.slug;
                    }
                    if (component.id === "LookupsList") {
                        if (
                            component.props.lookup &&
                            !lookupsToBeFetched.includes(component.props.lookup)
                        ) {
                            lookupsToBeFetched.push(component.props.lookup);
                        }
                    }
                    if (component.id === "AffiliateOfferList") {
                        if (
                            component.props.source &&
                            !["auto", "external"].includes(
                                component.props.source,
                            ) &&
                            !listingsToBeFetched.includes(
                                component.props.source,
                            )
                        ) {
                            listingsToBeFetched.push(component.props.source);
                        }
                        if (component.props.source === "external") {
                            fetchExternalListing = true;
                        }
                    }
                });
            });

            const [
                formOffer,
                exitModalOffer,
                thankYouOffers,
                headerOffer,
                stickyOffer,
            ] = initialOffersCalls(
                domain,
                req,
                res,
                categoryListingOffer,
                session.visitDetails,
                props.category?.slug,
            );

            props["formOffer"] = formOffer ?? [];
            props["exitModalOffer"] = exitModalOffer ?? [];
            props["thankYouOffers"] = thankYouOffers ?? [];
            props["headerOffer"] = headerOffer ?? [];
            props["stickyOffer"] = stickyOffer ?? [];

            if (formToBeFetchedCategory) {
                const { data: form } = await getDomainForm({
                    req,
                    res,
                    categorySlug: formToBeFetchedCategory,
                });
                props["form"] = form ?? null;
            }

            if (lookupsToBeFetched.length) {
                const promises = lookupsToBeFetched.map(async (lookup) => {
                    return getLookupsAndVariations({
                        req,
                        source: "lookups",
                        queries: {
                            orderBy: "label",
                            orderByDirection: "asc",
                            type: lookup,
                            variationsIds: undefined,
                            lookupsId: undefined,
                        },
                    });
                });

                const lookupLists = (await Promise.all(promises)).map(
                    (res) => res.data,
                );

                const temp: PageBuilderLookupLists = {};

                lookupLists.forEach((lookupList, index) => {
                    if (lookupList)
                        temp[lookupsToBeFetched[index]] = lookupList;
                });

                props["pageBuilderLookupLists"] = temp;
            }

            if (listingsToBeFetched.length) {
                const promises = listingsToBeFetched.map(async (slug) => {
                    return getListingBySlugV3({
                        req,
                        res,
                        slug,
                    });
                });

                const listings = (await Promise.all(promises)).map(
                    (res) => res.data,
                );

                const temp: PageBuilderListings = {};

                listings.forEach((listing, index) => {
                    if (listing) temp[listingsToBeFetched[index]] = listing;
                });

                props["pageBuilderListings"] = temp;
            }

            if (fetchExternalListing) {
                // crossSellingDomainFormId exists on domain category not on category
                const crossSellingDomainFormId = domain.categories.find(
                    (c) => c.slug === props["category"]?.slug,
                )?.crossSellingDomainFormId;

                type Data = { [x: string]: string };

                let fieldsObject: Data = {};

                try {
                    fieldsObject = JSON.parse(
                        Buffer.from(query.data as string, "base64").toString(
                            "utf-8",
                        ),
                    ) as Data;
                } catch {
                    // ???;
                }

                const finalQuestionsObject: Record<
                    string,
                    { name: string; value: string }
                > = {};

                Object.keys(fieldsObject).forEach((item) => {
                    finalQuestionsObject[item] = {
                        name: item,
                        value: fieldsObject[item],
                    };
                });

                const { data: clicksSearchList } = await getClicksSearchList({
                    questions: {
                        ...finalQuestionsObject,
                    },
                    categorySlug: props["category"]?.slug,
                    crossSellingDomainFormId,
                    req,
                    res,
                });

                if (clicksSearchList && clicksSearchList.result.length) {
                    clicksSearchList.result.forEach((item, index) => {
                        clicksSearchList.result[
                            index
                        ].slug = `${clicksSearchList?.result[index].slug}?id=${clicksSearchList.listRequestId}`;
                    });
                }

                props["clicksOffers"] = clicksSearchList?.result ?? null;
            }

            props["routeConfig"] = routeConfig ?? undefined;

            break;
        }
        case "do-not-sell": {
            props["externalFavicon"] = domain.favicon?.fullPath ?? null;
            const { data } = await getLookupsAndVariations({
                req,
                source: "lookups",
                queries: {
                    orderBy: "label",
                    orderByDirection: "asc",
                    type: "crm-company-states",
                    variationsIds: undefined,
                    lookupsId: undefined,
                },
            });
            props["doNotSellFormStates"] = data ?? null;
            props["pageTitle"] = "Do Not Sell";
            break;
        }
        case "unsubscribe": {
            props["pageTitle"] = "Unsubscribe";
            props["externalFavicon"] = domain.favicon?.fullPath ?? null;

            break;
        }
        case "form-completion": {
            props["pageTitle"] = "Complete the Form Now, Get Your Free Quote";
            props["externalFavicon"] = domain.favicon?.fullPath ?? null;

            const { data: piiCompletionData, error } = await getPiiCompletion({
                req,
                piiToken: query["pii-token"] as string,
            });

            if (error || !piiCompletionData) {
                if (error?.response?.status === 498) {
                    props["redirectTo"] = "/";
                    return {
                        props,
                    };
                }

                if (error?.response?.status === 404) {
                    return {
                        notFound: true,
                    };
                }

                return {
                    redirect: {
                        destination: "/",
                        permanent: false,
                    },
                };
            }

            props["piiCompletionData"] = piiCompletionData;
            props["category"] = piiCompletionData.category;

            const { data: form } = await getDomainForm({
                req,
                res,
                categorySlug: piiCompletionData.category.slug,
            });

            const [
                formOffer,
                exitModalOffer,
                thankYouOffers,
                headerOffer,
                stickyOffer,
            ] = initialOffersCalls(
                domain,
                req,
                res,
                categoryListingOffer,
                session.visitDetails,
                piiCompletionData.category.slug,
            );
            props["formOffer"] = formOffer ?? [];
            props["exitModalOffer"] = exitModalOffer ?? [];
            props["thankYouOffers"] = thankYouOffers ?? [];
            props["headerOffer"] = headerOffer ?? [];
            props["stickyOffer"] = stickyOffer ?? [];
            props["form"] = form ?? null;
            break;
        }
        case "common-offers":
        case "common-offers-generic":
        case "common-offers-generic-as-category": {
            props["formattedTitle"] = genericRouteTitle?.formattedTitle ?? null;
            props["externalFavicon"] = domain.favicon?.fullPath ?? null;

            if (query?.sf && props["category"]?.slug) {
                const { data: form } = await getDomainForm({
                    req,
                    res,
                    categorySlug: props["category"]?.slug,
                });
                props["form"] = form ?? null;
            }

            if (query?.si && props["category"]) {
                const crossSellingDomainFormId = domain.categories.find(
                    (c) => c.slug === props["category"]?.slug,
                )?.crossSellingDomainFormId;

                const { data: clicksSearchList } = await getClicksSearchList({
                    questions: {},
                    categorySlug: props["category"]?.slug,
                    crossSellingDomainFormId,
                    req,
                    res,
                });

                if (clicksSearchList && clicksSearchList.result.length) {
                    clicksSearchList.result.forEach((item, index) => {
                        clicksSearchList.result[
                            index
                        ].slug = `${clicksSearchList?.result[index].slug}?id=${clicksSearchList.listRequestId}`;
                    });
                }
                props["clicksOffers"] = clicksSearchList?.result ?? null;
            }
            const [exitModalOffer] = initialOffersCalls(
                domain,
                req,
                res,
                categoryListingOffer,
                session.visitDetails,
            );

            props["exitModalOffer"] = exitModalOffer ?? [];
            break;
        }
        case "autoWarranty": {
            props["pageTitle"] = domain.title;
            props["category"] = domain.categories[0] ?? null;
            // const promises = await Promise.all([
            //     getMakers(req, categorySlugAlias as string, domain),
            //     fetchGql<IFetchPostsResponse>({
            //         query: postsQuery,
            //         req: req,
            //         variables: {
            //             limit: 3,
            //             offset: 0,
            //             categoryId: props?.categories?.[0]?.id ?? 2,
            //         },
            //     }),
            //     fetchGql<IFetchFaqResponse>({
            //         query: faqsQuery,
            //         req: req,
            //         variables: {
            //             categoryId: props?.categories?.[0]?.id ?? 2,
            //         },
            //     }),
            // ]);
            // const makersData = promises[0].data;
            // const posts = promises[1];
            // const faqs = promises[2];

            // return {
            //     props: {
            //         posts: posts.allPosts.map((item) => ({
            //             title: item.title,
            //             image: { fullPath: item?.image?.fullPath ?? "" },
            //             url: item.url.url,
            //         })),
            //         faqs: faqs.category.faqs,
            //         autoWarrantyMakers: makersData,
            //     },
            // };
            break;
        }
        case "partner-with-us": {
            props["externalFavicon"] = domain.favicon?.fullPath ?? null;

            const { data } = await getLookupsAndVariations({
                req,
                source: "lookups",
                queries: {
                    orderBy: "label",
                    orderByDirection: "asc",
                    type: "crm-company-states",
                    variationsIds: undefined,
                    lookupsId: undefined,
                },
            });
            props["doNotSellFormStates"] = data ?? null;
            props["pageTitle"] = "Partner With Us";

            break;
        }
        default:
            return {
                notFound: true,
            };
    }

    if (genericRouteTitle?.formattedTitle) {
        props["pageTitle"] = genericRouteTitle.formattedTitle?.replace(
            /<br>/g,
            "",
        );
    }

    if (routeTitle) {
        props["pageTitle"] = routeTitle;
    }

    if (options) {
        props = {
            ...props,
            ...(await handleOptions({ options, props, req })),
        };
    }

    return {
        props,
    };
};
